import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import VendorLayout from "src/layouts/VendorLayout";

import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LoginPage")),
  },
  {
    exact: true,
    path: "/signUp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/SignupButton.js")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/reset-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetOtp")),
  },
  {
    exact: true,
    path: "/signup-vendor",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/vendor-dashboard",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/VendorSection/Dashboard")),
  },
  {
    exact: true,
    path: "/client-intrested",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/component/ClientIntrested.js")),
  },
  {
    exact: true,
    path: "/vendor-product",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/VendorSection/Product")),
  },
  {
    exact: true,
    path: "/vendor-add-edit-product",
    layout: VendorLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Product/AddEditProduct")
    ),
  },
  {
    exact: true,
    path: "/vendor-services",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/VendorSection/Services")),
  },
  {
    exact: true,
    path: "/view-appointment",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/AppoinementSection/ViewAppointment.js")
    ),
  },
  {
    exact: true,
    path: "/add-service",
    layout: VendorLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Services/AddService")
    ),
  },
  {
    exact: true,
    path: "/add-pets",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Pet/AddPet")),
  },
  {
    exact: true,
    path: "/edit-pet",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Pet/EditPet.js")),
  },
  {
    exact: true,
    path: "/vendor-pet",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Pet/index.js")),
  },
  {
    exact: true,
    path: "/pet-details",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Pet/PetDeatils")),
  },
  {
    exact: true,
    path: "/my-pet-list",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Pet/MyPetList")),
  },
  {
    exact: true,
    path: "/vendor-profile",
    layout: VendorLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/VendorSection/VendorProfile")
    ),
  },
  {
    exact: true,
    path: "/vendor-setting",
    layout: VendorLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/VendorSection/VendorSetting")
    ),
  },
  {
    exact: true,
    path: "/vendor-reward",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/VendorSection/VendorReward")),
  },
  {
    exact: true,
    path: "/vendor-notification",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Notification")),
  },
  {
    exact: true,
    path: "/notification",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Notification/Notification")),
  },
  {
    exact: true,
    path: "/add-services",
    layout: VendorLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Services/AddService")
    ),
  },
  {
    exact: true,
    path: "/edit-services",
    layout: VendorLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Services/EditService")
    ),
  },
  {
    exact: true,
    path: "/view-service",
    layout: VendorLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Services/ServiceTab")
    ),
  },
  {
    exact: true,
    path: "/edit-product",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Product/EditProduct")
    ),
  },
  {
    exact: true,
    path: "/view-product",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/VendorSection/Product/ProductTabs")
    ),
  },
  {
    exact: true,
    path: "/events",
    guard: true,
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/Event")),
  },
  {
    exact: true,
    path: "/event-detail",
    guard: true,
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/Event/EventDetails")),
  },
  {
    exact: true,
    path: "/event-list",
    guard: true,
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/Event/EventList")),
  },
  {
    exact: true,
    path: "/add-event",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Event/AddEvent")),
  },
  {
    exact: true,
    path: "/tracking-devices",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/TrackingDevices")),
  },
  {
    exact: true,
    path: "/webvoice",
    guard: true,
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/VideoCall")),
  },
  {
    exact: true,
    path: "/vendor-chat",
    layout: VendorLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/chat")),
  },
  {
    exact: true,
    path: "/webvideo",
    guard: true,
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/webvideo")),
  },
  {
    exact: true,
    path: "/doctor-type",
    guard: true,
    layout: VendorLayout,
    component: lazy(() => import("src/component/DoctorType.js")),
  },
  {
    exact: true,
    path: "/appointment",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/AppoinementSection/index.js")
    ),
  },

  {
    exact: true,
    path: "/confirmed-appointment",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/AppoinementSection/TeleHealthTabs")
    ),
  },
  {
    exact: true,
    path: "/completed-appointment",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/AppoinementSection/index.js")
    ),
  },
  {
    exact: true,
    path: "/friend-request",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/Friends/index.js")
    ),
  },
  {
    exact: true,
    path: "/my-friend",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/Friends/myfriends.js")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    guard: true,
    layout: VendorLayout,
    component: lazy(() =>
      import("src/views/pages/Friends/view-user.js")
    ),
  },
  {
    exact: true,
    path: "/raing-list",

    guard: true,
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/RatingHistory/index.js")),
  },
  {
    exact: true,
    path: "/block-user-list",

    guard: true,
    layout: VendorLayout,
    component: lazy(() => import("src/views/pages/BlockUserList/index.js")),
  },

  {
    exact: true,
    path: "/no-data-found",
    component: lazy(() => import("src/component/NoDataFound")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
