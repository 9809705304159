/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { MdEmojiEvents } from "react-icons/md";
import { MdOutlinePets } from "react-icons/md";
import PropTypes from "prop-types";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { FaUserLock } from "react-icons/fa";
import { FaDog } from "react-icons/fa";
import Apiconfigs from "src/apiConfig/config";
import { AuthContext } from "src/context/Auth";
import DeactivateModal from "src/component/DeactivateModal";
import { toast } from "react-toastify";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import {
  BsBox,
  AiOutlineDashboard,
  GiReceiveMoney,
  IoIosContact,
  AiFillCalendar,
  FiSettings,
  AiOutlineUser,
  AiOutlineUsergroupDelete,
  RiLogoutCircleLine,
} from "react-icons/all";
import { MdMiscellaneousServices } from "react-icons/md";
import Logo from "src/component/Logo";
import { Link } from "react-router-dom";
import { MdAddReaction } from "react-icons/md";
import NavItem from "./NavItem";
import { localeFunction } from "src/utils";
function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}
function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 240,
    // top: 72,
    top: 63,
    // left: "30px",
    height: "100%",
    background: "#ffff",
    borderRight: "0",
    boxShadow: "4px 3px 4px rgba(0, 0, 0, 0.1)",
    padding: "0px 20px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#6FCFB9",
    },
    "& svg": {
      color: "#6FCFB9",
      fontSize: "20px",
    },
  },
  LogoutBtn: {
    color: "#FE5151",
    // border: "1px solid #D1D1D1",
    borderRadius: "10px",
    fontWeight: "400",
    "& svg": {
      fontSize: "20px",
    },
  },
  RouterProfile: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    marginBottom: "6px",
    padding: "7px 15px",
    marginTop: "5px",
    "&:hover": {
      background: "#dcdcdc4d",
      borderRadius: "10px",
      textDecoration: "none !important",
      "& span": { color: "#6fcfb9" },
    },
    "&:focus": {
      textDecoration: "none !important",
    },
    "& span": { fontSize: "14px", fontWeight: "500", color: "#404040" },

    "& icon": {},
  },
  IconCLass: {
    fontSize: "30px",
    padding: "2px",
    border: "2px solid #6fcfb9",
    borderRadius: "29px",
    color: "white",
    background: "#6fcfb9",
  },
  btnBox: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      color: "#ffff",
      background: "#6FCFB9",
      borderRadius: "10px",
      cursor: "pointer",
    },
  },
}));
const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [open_activate, setOpenActivate] = useState(false);
  const [_isloading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const DeActivate = () => {
    setOpenActivate(true);
  };
  const sections = [
    {
      items: [
        {
          title: `${localeFunction("Dashboard")}`,
          icon: AiOutlineDashboard,
          href: "/vendor-dashboard",
        },
        {
          title: `${localeFunction("favourite_text_5")}`,
          icon: BsBox,
          href: "/vendor-product",
        },
        {
          title: `${localeFunction("interested_text_3")}`,
          icon: MdMiscellaneousServices,
          href: "/vendor-services",
        },
        {
          title: `${localeFunction("Add_Pets")}`,
          icon: MdAddReaction,
          href: "/add-pets",
        },
        {
          title: `${localeFunction("My_Pets")}`,
          icon: FaDog,
          href: "/my-pet-list",
        },
        {
          title: `${localeFunction("Events")}`,
          icon: MdEmojiEvents,
          href: "/events",
        },
        {
          title: `${localeFunction("Rewards")}`,
          icon: GiReceiveMoney,
          href: "/vendor-reward",
        },
        {
          title: `${localeFunction("Settings")}`,
          icon: FiSettings,
          href: "/vendor-setting",
        },
      ],
    },
  ];
  const VENDORPRODUCT = [
    {
      items: [
        {
          title: `${localeFunction("Dashboard")}`,
          icon: AiOutlineDashboard,
          href: "/vendor-dashboard",
        },
        {
          title: `${localeFunction("favourite_text_5")}`,
          icon: BsBox,
          href: "/vendor-product",
        },
        // {
        //   title: `${localeFunction("Listed_Products")}`,
        //   icon: BsBox,
        //   href: "/vendor-product",
        // },
        // {
        //   title: `${localeFunction("Pending_Products")}`,
        //   icon: BsBox,
        //   href: "/vendor-product",
        // },
        // {
        //   title: `${localeFunction("Rejected_Products")}`,
        //   icon: FaDog,
        //   href: "/vendor-product",
        // },
        {
          title: `${localeFunction("Rewards")}`,
          icon: GiReceiveMoney,
          href: "/vendor-reward",
        },
        {
          title: `${localeFunction("Settings")}`,
          icon: FiSettings,
          href: "/vendor-setting",
        },
      ],
    },
  ];
  const VENDORSERVICE = [
    {
      items: [
        {
          title: `${localeFunction("Dashboard")}`,
          icon: AiOutlineDashboard,
          href: "/vendor-dashboard",
        },
        {
          title: `${localeFunction("Total_Services")}`,
          icon: BsBox,
          href: "/vendor-services",
        },
        // {
        //   title: `${localeFunction("Approve_Services")}`,
        //   icon: BsBox,
        //   href: "/vendor-services",
        // },
        // {
        //   title: `${localeFunction("Pending_Services")}`,
        //   icon: BsBox,
        //   href: "/vendor-services",
        // },
        {
          title: `${localeFunction("Rewards")}`,
          icon: GiReceiveMoney,
          href: "/vendor-reward",
        },
        {
          title: `${localeFunction("Settings")}`,
          icon: FiSettings,
          href: "/vendor-setting",
        },
      ],
    },
  ];
  const VENDORPET = [
    {
      items: [
        {
          title: `${localeFunction("Dashboard")}`,
          icon: AiOutlineDashboard,
          href: "/vendor-dashboard",
        },
        {
          title: `${localeFunction("total_pets")}`,
          icon: BsBox,
          href: "/my-pet-list",
        },
        {
          title: `${localeFunction("Client_Pet")}`,
          icon: BsBox,
          href: "/client-intrested",
        },
        {
          title: `${localeFunction("Rewards")}`,
          icon: GiReceiveMoney,
          href: "/vendor-reward",
        },
        {
          title: `${localeFunction("Settings")}`,
          icon: FiSettings,
          href: "/vendor-setting",
        },
      ],
    },
  ];
  const VENDORDOCTORVET = [
    {
      items: [
        {
          title: `${localeFunction("Dashboard")}`,
          icon: AiOutlineDashboard,
          href: "/vendor-dashboard",
        },
        {
          title: `${localeFunction("Confirmed_Appointment")}`,
          icon: AiFillCalendar,
          href: "/confirmed-appointment",
        },
        {
          title: `${localeFunction("History_Appointment")}`,
          icon: AiFillCalendar,
          href: "/completed-appointment",
        },
        {
          title: `${localeFunction("FEEDBACK")}`,
          icon: AiFillCalendar,
          href: "/raing-list",
        },
        {
          title: `${localeFunction("FRIEND-REQUESTS")}`,
          icon: AiOutlineUser,
          href: "/friend-request",
        },
        {
          title: `${localeFunction("MYFRIENDS")}`,
          icon: AiOutlineUser,
          href: "/my-friend",
        },
        {
          title: `${localeFunction("Block_list")}`,
          icon: AiOutlineUsergroupDelete,
          href: "/block-user-list",
        },        
        {
          title: `${localeFunction("Settings")}`,
          icon: FiSettings,
          href: "/vendor-setting",
        },
      ],
    },
  ];
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const handleActivateAccount = async () => {
    try {
      setIsLoading(true);
      const res = await Axios({
        url: Apiconfigs.deactivateUserAccount,
        method: "Post",
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res) {
        setIsLoading(false);
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("password");
        window.localStorage.removeItem("mobileNumber");
        window.sessionStorage.removeItem("token");
        history.push("/");

        if (res?.data?.responseCode === 200) {
          toast.success(res?.data?.responseMessage);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box>
          <Box className={classes.ProfileSection}>
            <NavLink
              className={classes.RouterProfile}
              to={{
                pathname: "/vendor-profile",
              }}
            >
              <Avatar
                className={classes.IconCLass}
                style={{
                  background: "none",
                  padding: "0",
                  objectFit: "cover",
                  width: "35px",
                  height: "35px",
                }}
                src={
                  auth?.userData?.profilePic
                    ? auth?.userData?.profilePic
                    : "images/profile-placeholder.jpeg"
                }
              />
              &nbsp;&nbsp;&nbsp;
              <span>
                {auth?.userData?.name
                  ? auth?.userData?.name
                  : window.localStorage.getItem("userName")}
              </span>
            </NavLink>
          </Box>
          {auth?.userData?.userType == "VENDORPRODUCT" && (
            <Box>
              {VENDORPRODUCT.map((section, i) => (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              ))}
            </Box>
          )}
          {auth?.userData?.userType == "VENDORSERVICE" && (
            <Box>
              {VENDORSERVICE.map((section, i) => (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              ))}
            </Box>
          )}
          {auth?.userData?.userType == "VENDORPET" && (
            <Box>
              {VENDORPET.map((section, i) => (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              ))}
            </Box>
          )}
          {auth?.userData?.userType == "VENDORDOCTORVET" && (
            <Box>
              {VENDORDOCTORVET.map((section, i) => (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              ))}
            </Box>
          )}

          <Box className={classes.btnBox} onClick={() => DeActivate()}>
            <Button className={classes.LogoutBtn}>
              <FaUserLock />
              &nbsp;&nbsp;&nbsp;{localeFunction("Deactivate_Account")}
            </Button>
          </Box>
          <Box className={classes.btnBox} onClick={() => setOpen(true)}>
            <Button className={classes.LogoutBtn}>
              <RiLogoutCircleLine />
              &nbsp;&nbsp;&nbsp;&nbsp;{localeFunction("RightDrawer_text_4")}
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>
      {open_activate && (
        <DeactivateModal
          open={open_activate}
          _isloading={_isloading}
          setIsLoading={setIsLoading}
          handleClose={() => setOpenActivate(false)}
          title={localeFunction("Deactivate_Account")}
          desc={localeFunction("Activate_account_messgae")}
          handleActivateAccount={handleActivateAccount}
        />
      )}
      <ConfirmationDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={"Logout"}
        desc={localeFunction("logout_text")}
        type=""
      />
      {console.log("open--->", open)}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>
            <Box className="leftBox">
              <Link to="/vendor-dashboard">
                <Logo width="125" />
              </Link>
            </Box>
            {content}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
