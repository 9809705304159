import React, { useContext } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { AuthContext } from "src/context/Auth";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { localeFunction } from "src/utils";

export default function AlertDialog({
  title,
  desc,
  isLoading,
  productName,
  open,
  handleClose,
  productData,
  deleteProductHandler,
  deleteServiceHandler,
  BlockDocter,
  type,
}) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const logoutHandler = () => {
    auth.logOutHandler(window.sessionStorage.getItem("token"));
    history.push("/");
    auth.userLogIn(false, null);
    window.sessionStorage.removeItem("token");
  };
  return (
    <div>
      {title === "Logout" ? (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {type != "BLOCK" ? title : `${localeFunction("UnblockUser")}`}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              {desc}
            </Typography>
            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                {localeFunction("Add_Missing_Pets_81")}
              </Button>
              <Button
                disabled={isLoading}
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  type != "BLOCK" ? logoutHandler() : BlockDocter();
                }}
              >
                {type != "BLOCK" ? (
                  <> {localeFunction("logout_text2")}</>
                ) : (
                  <>{localeFunction("my_Post_text_3")}</>
                )}

                {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {productName === "Product"
                  ? `${localeFunction("Product_Delete")}`
                  : `${localeFunction("Service_Delete")}`}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              {" "}
              {localeFunction("delete_this")}{" "}
              {productName === "Product"
                ? `${localeFunction("favourite_text_5")}`
                : `${localeFunction("favourite_text_4")}`}
            </Typography>

            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                {localeFunction("Add_Missing_Pets_81")}
              </Button>
              <Button
                disabled={isLoading}
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (productName === "Product") {
                    deleteProductHandler(productData?._id);
                  }
                  if (productName === "Service") {
                    deleteServiceHandler(productData?._id);
                  }
                }}
              >
                {localeFunction("logout_text2")}{" "}
                {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
