import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyBjrqrwMAT9qaWb396R0yTwKCFvSM3OMDA",
  authDomain: "cali-s-diary.firebaseapp.com",
  projectId: "cali-s-diary",
  storageBucket: "cali-s-diary.appspot.com",
  messagingSenderId: "965808662451",
  appId: "1:965808662451:web:bc62677239730b4048cdc6",
  measurementId: "G-0YZGV0DR0Q"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const fetchToken = async (setTokenFound, setToken,setNotificationErr) => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      vapidKey: 'BDN85musYknk5c6Hlh-JTT87EkeqXQHoit-WXLZJVtnuO9lTRds6gIguwl8oTiHfqFWA60lD8RpKRkPDTGkZtoY',
    });
    if (currentToken) {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setToken(currentToken)
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        setNotificationErr(true)
        // shows on the UI that permission is required 
      }
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
    setNotificationErr(true)
  }
};

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        // console.log('On message: ', messaging, payload);
        resolve(payload);
      });
    })()
  );