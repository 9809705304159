import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
// import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    PrivateTabIndicator: {
      colorPrimary: { backgroundColor: "transparent" },
    },

    MuiPickersClock: {
      clock: {
        background: "rgb(111 207 185) !important",
      },
      pin: {
        background: "#0a0b0b !important",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        background: "#0a0b0b !important",
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        background: "#fff !important",
      },
    },
    MuiMenu: {
      list: {
        maxHeight: "250px",
        overflow: "scroll",
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "contain",
        background: "#000",
      },
    },
    MuiSkeleton: {
      wave: {
        "&::after": {
          background:
            "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.04), #f7f1f1c7) !important",
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        display: "flow-root !important",
      },
      action: {
        display: "flow-root !important",
      },

      message: {
        textAlign: "center",
        color: "#404040 !important",
        fontSize: "16px !important ",
        fontWeight: "600 !important",
      },
    },
    MuiPickersDay: {
      dayDisabled: {
        color: "rgb(147 138 138 / 50%) !important",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&.Mui-disabled": {
          color: "#404040 !important",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#404040",
        fontSize: "18px",
        boxSizing: "border-box",
        listStyle: "none",
        fontWeight: "500",
        lineHeight: "48px",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgb(202 205 209)",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#FFFFFF",
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: "35px",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        padding: "15px",
      },
    },

    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#20574a",
      },
    },
    MuiSelect: {
      icon: {
        right: "8px",
        color: "#535353",
      },
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        paddingLeft: "15px",
        borderRadius: "18px",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#6FCFB9",
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "separate",
        borderSpacing: "0px 2px",
      },
    },
    MuiTableCell: {
      body: {
        padding: "10px 15px",
      },
      root: {
        borderBottom: "none",
        background: "#F6F6F6",
      },
      head: {
        background: "#6FCFB9",
        color: "#FFFFFF",
      },
    },
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          background: "#6fcfb9 !important",
          color: "#fff !important",
        },
      },
      root: {
        backgroundColor: "#F1F1F1",
      },
    },
    MuiInput: {
      root: {
        borderRadius: "10px",
        padding: "5px 10px",
      },
      underline: {
        "&:hover": {
          borderBottom: "none",
        },
        "&::before": {
          borderBottom: "none",
          display: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
    MuiContainer: {
      // root: {
      //   "@media(min-width:600px)": {
      //     paddingLeft: "0px",
      //     paddingRight: "0px",
      //   },
      // },
    },
    MuiInputBase: {
      input: {
        height: "2.1876em !important",
        "@media (max-width:767px)": {
          height: "18px !important",
        },
        "&.Mui-disabled": {
          color: "#000 !important",
        },
      },
      root: {
        cursor: "text",
        display: "inline-flex",
        position: "relative",
        fontSize: "12px",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: "'Inter', sans-serif",
        fontWeight: "500",
        lineHeight: "1.1876em",
        letterSpacing: "0.00938em",
        backgroundColor: "#ffffff",
        border: "1px solid #D2D2D2",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      },
    },
    MuiFormGroup: {
      root: {
        display: "flex",
        flexWrap: "inherit",
        flexDirection: "row",
        justifyContent: "space-around",
      },
    },
    MuiFormControlLabel: {
      label: {
        "&.Mui-disabled": {
          color: "#404040 !important",
        },
      },
      root: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: "1px",
        // marginRight: "16px",
        verticalAlign: "middle",
        // "&.Mui-disabled": {
        //   color: "#6fcfb9 !important",
        // },
      },
    },
    MuiFormControl: {
      marginNormal: { margin: "0 !important" },
      marginDense: {
        marginTop: "0px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "#ACACAC",
        padding: "8px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: "14px",
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      contained: {
        boxShadow: "none",
        "&.Mui-disabled": {
          backgroundColor: "#6FCFB9 !important",
        },
      },
      textPrimary: {
        color: "#404040",
        fontWeight: 400,
        fontSize: "14px !important",
        lineHeight: "17px",
      },
      containedSizeSmall: {
        padding: "4px 10px",
        fontSize: "0.8125rem",
      },
      containedPrimary: {
        color: "#404040",
        backgroundColor: "#DEDEDE",
      },
      containedSecondary: {
        color: "#fff",
        backgroundColor: "#6FCFB9",
        border: "1px solid transparent",
        "&:hover": {
          backgroundColor: "#fff !important",
          color: "#6FCFB9",
          border: "1px solid #6FCFB9",
        },
      },

      outlinedSecondary: {
        color: "#6FCFB9",
        border: "1px solid #6FCFB9",
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#6FCFB9",
          color: "#fff",
          border: "1px solid #6FCFB9",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "10px",
        "@media (max-width:767px)": {
          borderRadius: "5px",
        },
      },
      elevation1: {
        boxShadow: "none",
      },
      elevation4: {
        boxShadow: "none !important",
      },
    },

    MuiOutlinedInput: {
      inputAdornedEnd: {
        "@media (max-width:767px)": {
          padding: "6px 8px",
          fontSize: "10px",
        },
      },
      inputAdornedStart: {
        paddingLeft: 14,
      },
      root: {
        position: "relative",
        borderRadius: "10px",
        height: "50px",
        "@media (max-width:599.9px)": {
          borderRadius: "5px",
          height: "35px",
        },
      },
      input: {
        padding: "11px 14px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "none",
        },
        "@media (max-width:767px)": {
          padding: " 6px 8px",
          fontSize: "10px",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "none",
        paddingBottom: "none",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },

    MuiFormHelperText: {
      root: {
        fontSize: "12px !important",
        "& .Mui-error": {
          fontSize: " 12px !important",
        },
        "& p": {
          fontSize: " 12px !important",
        },
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiInputBase: {
        root: {
          backgroundColor: " #FFFFFF !important",
        },
        input: {
          height: "2.1876em !important",
          "&::placeholder": {
            opacity: 1,
            color: "#BDBDBD",
          },
          Mui: {
            disabled: {
              color: "#BDBDBD",
            },
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "rgba(255, 255, 255, 1);",
        dark: "#f4f6f8",
        paper: "#ECECEC",
      },
      primary: {
        main: "#000080",
      },
      secondary: {
        main: "#FF9933",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    // shadows: softShadows,
  },
  {
    name: "DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#F5F5F5;",
        dark: "#1c2025",
        paper: "#ffffff",
      },
      primary: {
        main: "#6FCFB9",
      },
      secondary: {
        main: "#6FCFB9",
      },
      text: {
        primary: "#404040",
        secondary: "#202020",
      },
    },
    // shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
