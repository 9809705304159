import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { useHistory, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  acceptBtn: {
    fontSize: "14px",
    color: "green",
  },
  rejectBtn: {
    fontSize: "14px",
    color: "red",
  },
}));

export default function NotificationAlertPopUp({
  open,
  setOpen,
  data,
  notification,
}) {
  const [snackPack, setSnackPack] = useState([]);
  // const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClick = (message) => () => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const classes = useStyles();
  return (
    <div>
      {/* <Button onClick={handleClick("Message A")}>Show</Button> */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000000}
        onClose={handleClose}
        message={notification ? notification.title : ""}
        action={
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              style={{ maxWidth: "380px" }}
            >
              <img
                src={data?.profilePic}
                style={{ width: "60px", height: "60px", marginRight: "10px" }}
              />
              <Typography variant="body1"
              style={{color:"#404040",fontSize:"16px",fontWeight:"600"}}
              >
                {notification ? notification.body : ""}
              </Typography>
              {!data?.callType && (<>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  className={classes.rejectBtn}
                  onClick={handleClose}
                >
                  <CloseIcon />
                  Close
                </IconButton></>)}
            </Box>
            {data?.callType && (<Box display={"flex"} justifyContent={"center"}>
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.acceptBtn}
                onClick={() => {
                  if (data.callType == 'Video') {
                    history.push({
                      pathname: "/webvideo",
                      state: {
                        token: data?.token,
                        channel: data?.channelId,
                      },
                    });
                  } else {
                    history.push({
                      pathname: "/webvoice",
                      state: {
                        token: data?.token,
                        channel: data?.channelId,
                      },
                    });
                  }
                  handleClose();
                }}
              >
                <DoneIcon />
                Accept
              </IconButton>
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.rejectBtn}
                onClick={handleClose}
              >
                <CloseIcon />
                Decline
              </IconButton>
            </Box>)}
          </>
        }
      />
    </div>
  );
}
