// import { useDispatch, useSelector } from 'react-redux';

function loadData(key) {
  try {
    let data = localStorage.getItem(key);
    data = JSON.parse(data);
    return data;
  } catch (err) {
    return undefined;
  }
}
function isActionAuth(model, key, _pageAuthentication) {
  try {
    if (_pageAuthentication !== undefined && _pageAuthentication.length) {
      let el = _pageAuthentication;
      if (el.length) {
        let modelFileter = el.find((e) => e.key === model);
        if (modelFileter) {
          let actions = modelFileter.actions.find((e) => e.key === key);
          if (actions) {
            return actions.access;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  } catch (err) {
    console.log("err", err);
  }
}

function getToken() {
  try {
    return localStorage.getItem("token");
  } catch (err) {
    return undefined;
  }
}
function saveData(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
  localStorage.setItem("token", data.token);
}
function deleteData(key) {
  localStorage.removeItem(key);
  localStorage.removeItem("token");
  sessionStorage.removeItem("session");
}
export { loadData, saveData, deleteData, getToken, isActionAuth };
