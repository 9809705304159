import React from "react";

const Logo = (props) => {
  return (
    <img
      // onClick={() => history.push("/vendor-dashboard")}
      src="/images/logo.png"
      alt="Logo"
      style={{ cursor: "pointer", paddingTop: "9px", width: "194px" }}
      {...props}
    />
  );
};

export default Logo;
