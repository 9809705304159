import { loadData, saveData, deleteData } from "src/utils/sessionStorage";
import React, { useState, useContext } from "react";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  STORE_FIRE_TOKEN,
  SET_LANG,
} from "../Auth/actionType";
import { AuthContext } from "src/context/Auth";

const loginInfo = loadData("loginInfo");
// const auth = useContext(AuthContext);
// console.log("authasd-->", auth);
const initState = {
  isAuth: loginInfo ? true : false,
  isLoading: false,
  isLoggedIn: false,
  isError: false,
  token: loginInfo || "",
  errorMessage: "",
  firebaseToken: "",
  langCode: window.localStorage.getItem("languageId")
    ? window.localStorage.getItem("languageId")
    : "en",
};
export const authReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      saveData("loginInfo", payload);
      return {
        ...state,
        isLoading: true,
        isAuth: true,
        token: payload.token,
        isLoggedIn: false,
      };
    case STORE_FIRE_TOKEN:
      return {
        ...state,
        isLoading: false,
        isAuth: false,
        isError: false,
        isLoggedIn: false,
        firebaseToken: payload.firetoken,
        token: "",
        errorMessage: "",
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: payload.errMessage,
        token: "",
        isLoggedIn:
          payload.isLoggedIn !== undefined ? payload.isLoggedIn : false,
      };
    case LOGOUT:
      deleteData("loginInfo");
      return {
        ...state,
        isLoading: false,
        isAuth: false,
        isError: false,
        isLoggedIn: false,
      };
    case SET_LANG:
      return {
        ...state,
        langCode: payload.langCode,
      };
    default:
      return state;
  }
};
